
.data-center {
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    padding: 0px 30px 0;
    .hidden-box {
        display: none;
    }
    .operate-box {
        display: flex;
        justify-content: flex-start;
        height: 90px;
        border-bottom: 1px solid #DADCE7;
        margin-bottom: 32px;
        .left-filter {
            display: flex;
            align-items: center;
            color: #332d42;
            font-size: 14px;
        }
        .right-filter {
            display: flex;
            align-items: center;
            color: #332d42;
            font-size: 14px;
            margin-left: 40px;
        }
        .update-time {
            display: flex;
            align-items: center;
            width: 230px;
            color: #332d42;
            font-size: 14px;
            margin-left: 36.1%;
        }
        .label {
            margin-right: 10px;
        }
        ::v-deep .el-select {
            width: 250px;
            .el-icon-arrow-up:before {
                content: "\e790";
                color: #342e43;
                font-size: 20px;
            }
        }
    }
    .total-chart {
        display: flex;
        justify-content: flex-start;

        .item{
            flex: 1;
            height: 235px;
            border: 1px solid #DADCE7;
            border-radius: 10px;
        }
        .item1 {
            margin-left: 26px;
        }
        .total-title {
            font-size: 16px;
            color: #332D42;
            margin: 20px 0 0 32px;
        }
        .total-numb {
            height: 24px;
            font-size: 30px;
            color: #332D42;
            margin: 25px 0 0 32px;
        }
        .sum-chart {
            height: 80px;
            margin: 10px 31px 0 26px;
            border-bottom: 1px solid #DADCE7;
        }
        .average-numb {
            font-size: 14px;
            color: #332D42;
            margin: 15px 0 0 27px;
        }
        .audience {
            background: url('../../../assets/image/teacherDataCenter/audience-img.png') no-repeat;
            background-size: 448px;
        }
        .order {
            background: url('../../../assets/image/teacherDataCenter/order-img.png') no-repeat;
            background-size: 448px;
        }
        .sales {
            background: url('../../../assets/image/teacherDataCenter/sales-img.png') no-repeat 16px 0;
            background-size: 448px;
        }
    }
    .view-order-sales-chart {
        height: 534px;
        border: 1px solid #DADCE7;
        border-radius: 6px;
        margin-top: 20px;

        .item {
            margin-left: 31px;
            height: 534px;
        }

        ::v-deep .el-tabs__nav {
            margin-left: 32px;
        }
        ::v-deep .el-tabs__active-bar {
            height: 4px;
            border-radius: 2px;
        }
        ::v-deep .el-icon-date:before {
            content: '\21C5'
        }
    }
    .rank-table {
        display: flex;
        flex-direction: column;
        margin: 28px 0 30px 0;

        .table-calss-title {
            height: 18px;
            font-size: 18px;
            color: #333333;
        }
        .rank-table-item {
            & * {
                box-sizing: border-box;
            }
            box-sizing: border-box;
            margin-top: 10px;
            border: 1px solid #DADCE7;
        }
        .table-title {
            font-size: 16px;
            color: #332D42;
        }
        .table-header-list {
            padding: 0 30px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 45px;

        }
    }
}
